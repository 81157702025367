import React, { useEffect, useState } from 'react';
import axios from 'axios';

const App = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    axios.get('https://laura-welther.de/wp-json/wp/v2/posts')
      .then(response => setPosts(response.data))
      .catch(error => console.error('Fehler beim Laden der Beiträge:', error));
  }, []);

  return (
    <div>
      <h1>WordPress Beiträge</h1>
      <ul>
        {posts.map(post => (
          <li key={post.id}>
            <h2>{post.title.rendered}</h2>
            <p dangerouslySetInnerHTML={{ __html: post.content.rendered }}></p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default App;
